import React from "react";

import * as styles from "./PopupJobOffer.module.scss";
import IPopupJobOffer from "./PopupJobOffer.type";
import CloseIcon from "../../images/Close-2.svg";
import Icon from "../Icon/Icon";
import Paragraph from "../Typography/Paragraph/Paragraph";
import TitleUnderlineParagraph from "../TitleUnderlineParagraph/TitleUnderlineParagraph";
import Button from "../Button/Button";

const PopupJobOffer = ({
    jobOffer,
    closePopup,
    openPopupForm,
}: IPopupJobOffer) => {
    return (
        <>
            <div className={styles.element}>
                <div className={styles.content}>
                    <TitleUnderlineParagraph
                        className={styles.name}
                        heading={jobOffer.jobTitle}
                        headingLevel="h3"
                        text={jobOffer.cityAndState}
                        textSize="medium"
                        underline="left"
                    />
                    <Paragraph size="medium" className={styles.description}>
                        {jobOffer.description}
                    </Paragraph>
                    <Button
                        className={styles.buttonApply}
                        type={"small"}
                        onClick={() => {
                            closePopup();
                            openPopupForm();
                        }}
                    >
                        Apply
                    </Button>

                    <button
                        className={styles.close}
                        onClick={() => {
                            closePopup();
                        }}
                    >
                        <Icon
                            className={styles.closeIcon}
                            altText="close popup"
                            localFile={{
                                publicURL: CloseIcon,
                            }}
                        />
                    </button>
                </div>
            </div>
            <div
                onClick={() => {
                    closePopup();
                }}
                className={styles.overlay}
            />
        </>
    );
};

export default PopupJobOffer;
