import React, { useEffect, useState } from "react";
import PopupFormJobOffer from "../../components/PopupFormJobOffer/PopupFormJobOffer";
import PopupJobOffer from "../../components/PopupJobOffer/PopupJobOffer";

import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import { setBodyOverflow } from "../../utils";
import * as styles from "./JobOffers.module.scss";
import IJobOffers from "./JobOffers.type";

const JobOffers = ({ jobOffers, formData }: IJobOffers) => {
    const [activeOffer, setActiveOffer] =
        useState<IJobOffers["jobOffers"][0]>();
    const [showPopupOffer, setShowPopupOffer] = useState(false);
    const [showPopupForm, setShowPopupForm] = useState(false);

    useEffect(() => {
        setBodyOverflow(showPopupOffer || showPopupForm ? "hidden" : "");
    }, [showPopupOffer, showPopupForm]);

    return (
        <section className={styles.section}>
            <div className="container">
                <ul className={styles.offersList}>
                    {jobOffers.map((offer, index) => (
                        <li
                            key={offer.jobTitle + index}
                            className={styles.offersListItem}
                        >
                            <button
                                className={styles.button}
                                onClick={() => {
                                    setActiveOffer(offer);
                                    setShowPopupOffer(true);
                                }}
                            >
                                <TitleUnderlineParagraph
                                    className={styles.name}
                                    heading={offer.jobTitle}
                                    headingLevel="h3"
                                    text={offer.cityAndState}
                                    textSize="medium"
                                    underline="center"
                                    align="center"
                                />
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {showPopupOffer && activeOffer && (
                <PopupJobOffer
                    jobOffer={activeOffer}
                    closePopup={() => setShowPopupOffer(false)}
                    openPopupForm={() => setShowPopupForm(true)}
                />
            )}

            {showPopupForm && (
                <PopupFormJobOffer
                    formData={formData}
                    jobTitle={activeOffer?.jobTitle}
                    closePopup={() => setShowPopupForm(false)}
                />
            )}
        </section>
    );
};

export default JobOffers;
