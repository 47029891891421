import React, { useState } from "react";
import FormJobOffer from "../Form/FormJobOffer/FormJobOffer";
import PopupFormJobOfferI from "./PopupFormJobOffer.type";
import * as styles from "./PopupFormJobOffer.module.scss";
import Icon from "../Icon/Icon";
import CloseIcon from "../../images/Close-2.svg";
import Heading from "../Typography/Heading/Heading";
import Paragraph from "../Typography/Paragraph/Paragraph";
import { cn } from "../../utils";

const PopupFormJobOffer = ({
    closePopup,
    jobTitle = "",
    formData,
}: PopupFormJobOfferI) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    return (
        <>
            <div className={styles.element}>
                {!isSubmitted ? (
                    <FormJobOffer
                        jobTitle={jobTitle}
                        formData={formData}
                        setIsSubmitted={setIsSubmitted}
                    />
                ) : (
                    <div className={styles.successMessageWrapper}>
                        <Heading level="h1" className={styles.successTitle}>
                            {formData.successTitle}
                        </Heading>
                        <Paragraph
                            size="medium"
                            className={styles.successMessage}
                        >
                            {formData.successMessage}
                        </Paragraph>
                    </div>
                )}

                <button
                    className={styles.close}
                    onClick={() => {
                        closePopup();
                    }}
                >
                    <Icon
                        className={cn(
                            styles.closeIcon,
                            isSubmitted && styles.submitted
                        )}
                        altText="close popup"
                        localFile={{
                            publicURL: CloseIcon,
                        }}
                    />
                </button>
            </div>
            <div className={styles.overlay} />
        </>
    );
};

export default PopupFormJobOffer;
