import React, { useState } from "react";
import { Form as FormikForm, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import * as styles from "./FormJobOffer.module.scss";
import Button from "../../Button/Button";
import FormInput from "../FormInput/FormInput";
import { TErrorKey, TTouchedKey } from "../Form.type";
import FormJobOfferI from "./FormJobOffer.type";

const FormJobOffer = ({
    jobTitle,
    formData,
    setIsSubmitted,
}: FormJobOfferI) => {
    const [responseMessage, setResponseMessage] = useState("");

    return (
        <Formik
            initialValues={{
                name: "",
                email: "",
                comments: "",
                fileUpload: "",
            }}
            validationSchema={Yup.object({
                name: Yup.string().required("Name is a required field."),
                email: Yup.string()
                    .email("Invalid email format.")
                    .required("Email is a required field."),
                fileUpload: Yup.mixed().required(
                    "Resume Upload is a required field."
                ),
            })}
            onSubmit={(values, { resetForm }) => {
                setResponseMessage("");

                let requestBody = new FormData();

                for (const property in values) {
                    requestBody.append(property, (values as any)[property]);
                }
                requestBody.append("targetEmail", formData.targetEmail);
                requestBody.append("jobTitle", jobTitle);

                const url = `${
                    (process.env.GATSBY_WP_URL || "http://christina.local") +
                    "/wp-json/christina/sendjoboffer/"
                }`;

                fetch(url, {
                    method: "POST",
                    body: requestBody,
                })
                    .then((resp) => {
                        if (!resp.ok) {
                            throw new Error("error");
                        }
                        return resp.json();
                    })
                    .then((data) =>
                        data.status === "error"
                            ? setResponseMessage(formData.errorMessage)
                            : (setIsSubmitted(true), resetForm({}))
                    )
                    .catch(
                        (err) => (
                            console.log(err),
                            setResponseMessage(formData.errorMessage)
                        )
                    );
            }}
        >
            {({ errors, touched }: FormikProps<{}>) => (
                <FormikForm className={styles.form}>
                    <FormInput
                        field={{
                            fieldGroupName: "input",
                            label: "Name",
                            name: "name",
                            required: true,
                        }}
                        touched={touched["name" as TTouchedKey]}
                        error={errors["name" as TErrorKey]}
                    />
                    <FormInput
                        field={{
                            fieldGroupName: "input",
                            label: "Email",
                            name: "email",
                            required: true,
                        }}
                        touched={touched["email" as TTouchedKey]}
                        error={errors["email" as TErrorKey]}
                    />
                    <FormInput
                        field={{
                            fieldGroupName: "textarea",
                            label: "Comments",
                            name: "comments",
                            required: false,
                        }}
                        touched={touched["comments" as TTouchedKey]}
                        error={errors["comments" as TErrorKey]}
                    />
                    <FormInput
                        field={{
                            fieldGroupName: "input",
                            label: "Resume Upload (.pdf)",
                            name: "fileUpload",
                            type: "upload",
                            accept: ".pdf",
                            required: true,
                        }}
                        touched={touched["fileUpload" as TTouchedKey]}
                        error={errors["fileUpload" as TErrorKey]}
                    />

                    <div className={styles.btnWrapper}>
                        <Button type={"small"} className={styles.button} submit>
                            Submit
                        </Button>
                    </div>

                    {responseMessage && (
                        <p className={styles.message}>{responseMessage}</p>
                    )}
                </FormikForm>
            )}
        </Formik>
    );
};

export default FormJobOffer;
